import React from 'react'

import SolutionView from 'domains/Solutions'
import SEO from 'components/seo'

function SolutionPage() {
  return (
    <>
      <SEO
        title="Smart Workforce Management"
        description="Get the best out of your employees. Improve on-ground coordination and accountability of personnel, cuts down processes on issue monitoring and resolution!"
      />
      <SolutionView title="smart workforce management" />
    </>
  )
}

export default SolutionPage
